import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import Layout from "../common/Layout";
import { strapi_base_url } from "../utils/base_url";
import React, { useEffect, useState } from "react";

function Privacypolicy() {
  const [privacyData, setPrivacyData] = useState([]);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/privacy-policy?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setPrivacyData(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const content =
    privacyData &&
    privacyData.data &&
    privacyData.data.attributes &&
    privacyData.data.attributes.content;
  const bannerImage =
    privacyData &&
    privacyData.data &&
    privacyData.data.attributes &&
    privacyData.data.attributes.bannerImage &&
    privacyData.data.attributes.bannerImage.data[0] &&
    privacyData.data.attributes.bannerImage.data[0].attributes &&
    privacyData.data.attributes.bannerImage.data[0].attributes.url;
  return (
    <div>
      <SEO 
       title={
        privacyData &&
        privacyData.data &&
        privacyData.data.attributes &&
        privacyData.data.attributes.metaTitle
      }
      description={
        privacyData &&
        privacyData.data &&
        privacyData.data.attributes &&
        privacyData.data.attributes.metaDescription
      }
      />
      <Layout>
        <div className="hero_shape position-relative">
          <div
            style={{
              backgroundImage: `url(${strapi_base_url}${bannerImage})`,
              height: "542px",
            }}
            className="  d-flex align-items-center justify-content-center text-center"
          >
            <div className="container bg_text ">
              <h1>
                {" "}
                {privacyData &&
                  privacyData.data &&
                  privacyData.data.attributes &&
                  privacyData.data.attributes.bannerTitle}
              </h1>
              <p>
                <a href="">Home</a>
                {privacyData &&
                  privacyData.data &&
                  privacyData.data.attributes &&
                  privacyData.data.attributes.bannerText}
              </p>
            </div>
          </div>
        </div>

        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Layout>
    </div>
  );
}

export default Privacypolicy;
