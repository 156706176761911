import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeDefault from "./pages/HomeDefault";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Contact from "./elements/Contact";
import Pressreleases from "./elements/press-releases";
import Privacypolicy from "./elements/privacy-policy";
import Termsofuse from "./elements/terms-of-use";
import DefaultTechnologies from "./pages/DefaultTechnologies";
import Product from "./elements/product/Product";
import { strapi_base_url } from "./utils/base_url";
import NotFound from "./pages/NotFound";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProductListSkeleton = () => (
  <div>
    {/* <Skeleton height={40} count={5} /> For listing items  */}
    <Skeleton height={70} />
    <Skeleton height={500} />
    <Skeleton height={200} /> {/* For image or card skeletons */}
  </div>
);

const App = () => {
  const [product, setProduct] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingTechnologies, setLoadingTechnologies] = useState(true);
  const [error, setError] = useState(null);

  const fetchTechnologies = async () => {
    try {
      const response = await fetch(
        `${strapi_base_url}/api/technologies?populate=*`
      );
      const technologiesData = await response.json();
      setTechnologies(technologiesData.data);
    } catch (error) {
      console.error("Error fetching Technologies pages:", error);
      setError("Failed to load technologies.");
    } finally {
      setLoadingTechnologies(false);
    }
  };

  const getProducts = async () => {
    try {
      const response = await fetch(
        `${strapi_base_url}/api/products?populate=*`
      );
      const productData = await response.json();
      setProduct(productData.data);
    } catch (error) {
      console.error("Error fetching Products pages:", error);
      setError("Failed to load Products.");
    } finally {
      setLoadingProducts(false);
    }
  };

  useEffect(() => {
    getProducts();
    fetchTechnologies();
  }, []);

  useEffect(() => {
  }, [product, technologies]);

  // If either of the data is still loading, render a loading state
  if (loadingProducts || loadingTechnologies) {
    return (
      <div>
        {/* <p>Loading...</p> */}
        <ProductListSkeleton />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomeDefault />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/press-releases" element={<Pressreleases />} />
          <Route path="/Privacy-policy" element={<Privacypolicy />} />
          <Route path="/Terms-of-use" element={<Termsofuse />} />

          {/* {product.map((page) => (
            <Route
              key={page.id}
              path={`/${page.attributes.slug}`}
              element={
                product.some(
                  (dynamicPage) =>
                    dynamicPage.attributes.slug === page.attributes.slug
                ) ? (
                  <Product productData={page.attributes} />
                ) : (
                  <Product />
                )
              }
            />
          ))}

          {technologies.map((page) => (
            <Route
              key={page.id}
              path={`/${page.attributes.slug}`}
              element={
                Technologies.some(
                  (dynamicPage) =>
                    dynamicPage.attributes.slug === page.attributes.slug
                ) ? (
                  <DefaultTechnologies technologyData={page.attributes} />
                ) : (
                  <DefaultTechnologies />
                )
              }
            />
          ))} */}
          {product.map((page) => (
            <Route
              key={page.id}
              path={`/${page.attributes.slug}`}
              element={<Product productData={page.attributes} />}
            />
          ))}

          {technologies.map((page) => (
            <Route
              key={page.id}
              path={`/${page.attributes.slug}`}
              element={<DefaultTechnologies technologyData={page.attributes} />}
            />
          ))}
          {/* 404 Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
