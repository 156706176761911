import React, { useEffect } from "react";
import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import Layout from "../common/Layout";
import { strapi_base_url } from "../utils/base_url";
function ProductTwo({ technologyData }) {
  useEffect(() => {}, [technologyData]);

  const technologyImage =
    technologyData.technologyImage &&
    technologyData.technologyImage.data[0] &&
    technologyData.technologyImage.data[0].attributes &&
    technologyData.technologyImage.data[0].attributes.url;

  return (
    <div>
      <SEO
        title={technologyData.title}
        description={"Checkpoint || Technologies"}
      />
      <Layout>
        {technologyImage && (
          <div className="container container_bg d-flex justify-content-center">
            <div className="img_infra d-flex justify-content-center">
              <img
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "25px",
                }}
                src={`${strapi_base_url}${technologyImage}`}
                className="product_image"
                alt="Tech Image"
              />
            </div>
          </div>
        )}
        <div
          className="container infra_heading p-4"
          style={{ backgroundColor: "#fdf3f2" }}
        >
          <h1>{technologyData.title}</h1>
          <p>{technologyData.description}</p>
        </div>

        {/* <section class="section_bg pt-5">
          <div class="container">
            <div class="container text-center sec_heading">
              <h1>Our Technologies</h1>
              <p>
                Our InfraScan™ Product lines provide the groundbreaking
                combination of Checkpoint Technologies, world leading laser
                scanning microscope and its best in class emission system into a
                single tool.{" "}
              </p>
            </div>
            {technologyImage && (
              <div class="heading_img">
                <div class="row pt-5 ">
                  <div class=" col-lg-6 col-md-6  col-sm-12 col-12">
                    <img
                      src={`${strapi_base_url}${technologyImage}`}
                      class="img-fluid"
                    />
                  </div>

                  <div class=" col-sm-12 col-md-6 col-lg-6 col-12 pt-5 infra-div">
                    <h1>{technologyData.title}</h1>
                    <p>{technologyData.description}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section> */}
      </Layout>
    </div>
  );
}

export default ProductTwo;
