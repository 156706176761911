import React, { useState } from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import "../css/style.css";
import "../css/example.css";
import { strapi_base_url } from "../utils/base_url";

function Contact() {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, company, message } = formData;

    try {
      const response = await fetch(`${strapi_base_url}/api/contacts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          company,
          message,
        }),
      });
      console.log("RESPONSE", response);
      if (response.ok) {
        // Email sent successfully
        alert("Email sent successfully!");
        setFormData({
          name: "",
          email: "",
          company: "",
          message: "",
        });
      } else {
        // Error sending email
        alert("Failed to send email. Please try again later.");
      }
    } catch (error) {
      console.error("Error in creating:", error);
      alert("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <div>
      <SEO title="Contact Us" description="Checkpoint || Contact Us" />
      <Layout>
        {/* contact us*/}
        {/* <div className="contact_image d-flex align-items-center"> */}
        <div className="hero_shape position-relative">
          <div className="d-flex align-items-center justify-content-center text-center">
            {isLoading && <div className="skeleton-loader"></div>}

            {/* Image with onLoad event to change state once loaded */}
            <img
              src="img/Checkpoint_ContactUsPage.webp"
              alt="Hero"
              width={"100%"}
              height={"500px"}
              // style={{ width: "100%", height: "500px" }}
              onLoad={handleImageLoad}
              style={
                isLoading ? { visibility: "hidden" } : { visibility: "visible" }
              } // Hide the image while loading
            />
            {/* <div
            style={{
              position: "absolute",
              height: "936px",
              width: "100%",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
              zIndex: 1, // Ensure overlay is above the background image
            }}
          /> */}
            {/* <div className="container contact_text" style={{ position: "relative", zIndex: 2 }}>
            <h1>Contact Sales</h1>
            <p>
              Checkpoint Technologies develops and manufactures innovative
              optical failure analysis{" "}
            </p>
          </div> */}
          </div>
        </div>

        {/* form */}
        <div className="container form_contact mt-5 mb-5">
          <div className="row">
            <div className="col-lg-5 col-sm-12 contact_info">
              <h1>CONTACT</h1>
              <p>
                Email us with any questions at rhand@checkpointtechnologies.com
                or use the form and one of our representatives will contact you
                shortly.
              </p>
              <div className="d-flex">
                <span className="social-icon mt-1">
                  <i className="fa-solid fa-phone" />
                </span>
                <a href="tel:+415-910-5009">
                  Phone:
                  <br />
                  480-206-8133
                </a>
              </div>
              <div className="d-flex mt-3">
                <span className="social-icon mt-1">
                  <i className="fa-solid fa-envelope" />
                </span>
                <a href="mailto:rhand@checkpointtechnologies.com">
                  Email:
                  <br />
                  rhand@checkpointtechnologies.com
                </a>
              </div>
              <div className="d-flex mt-3">
                <span className="social-icon mt-1">
                  <i className="fa-solid fa-location-dot" />
                </span>
                <a
                  href="https://maps.google.com?q=66+Bonaventura+Drive,+San+Jose,+CA+95134"
                  target="_blank"
                >
                  Location:
                  <br />
                  66 Bonaventura Drive, San Jose, CA 95134
                </a>
              </div>
            </div>
            <div className="col-lg-6 form_login container mt-lg-0 mt-5">
              <h2>SEND US A MESSAGE</h2>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-6 position-relative">
                    <span className="position-absolute user-icon" />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 position-relative">
                    <span className="position-absolute user-icon" />
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
                <div className="position-relative">
                  <span className="position-absolute user-icon" />
                  <textarea
                    className="form-control"
                    placeholder="Your Message"
                    rows={5}
                    defaultValue={""}
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <div className="form_btn">
                  <button type="submit" className="text-start">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default Contact;
