import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../css/style.css";
import "../../css/example.css";
import { strapi_base_url } from "../../utils/base_url";
import { useNavigate } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';

function Nav() {
  const navigate = useNavigate();
  const [headerMenu, setHeaderMenu] = useState([]);
  const [product, setProduct] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  // const [scrollToEvents, setScrollToEvents] = useState(false);

  useEffect(() => {
    fetch(`${strapi_base_url}/api/menus/?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setHeaderMenu(
          responseData.data[0] &&
          responseData.data[0].attributes &&
          responseData.data[0].attributes.items &&
          responseData.data[0].attributes.items.data
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getProducts = async () => {
    try {
      const response = await fetch(`${strapi_base_url}/api/products?populate=*`);
      const productData = await response.json();
      setProduct(productData.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchTechnologies = async () => {
    try {
      const response = await fetch(`${strapi_base_url}/api/technologies?populate=*`);
      const technologiesData = await response.json();
      setTechnologies(technologiesData.data);
    } catch (error) {
      console.error("Error fetching technologies:", error);
    }
  };

  useEffect(() => {
    fetchTechnologies();
    getProducts();
  }, []);

  const handleScrollToEvents = () => {
    navigate('/#events', { replace: true });

    // Add a small delay to ensure the page fully loads before scrolling
    setTimeout(() => {
      const eventsSection = document.getElementById("events");
      if (eventsSection) {
        eventsSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 750);
  };

  // const handleScrollToEvents = () => {
  //   const eventsSection = document.getElementById("events");

  //   if (eventsSection) {
  //     // If on the home page, scroll directly to the section
  //     eventsSection.scrollIntoView({ behavior: "smooth" });
  //   } else {
  //     // Navigate to the home page and set scroll flag
  //     setScrollToEvents(true);
  //     navigate('/');
  //   }
  // };

  // useEffect(() => {
  //   // Check if we need to scroll after navigation
  //   if (scrollToEvents) {
  //     const eventsSection = document.getElementById("events");
  //     if (eventsSection) {
  //       eventsSection.scrollIntoView({ behavior: "smooth" });
  //       setScrollToEvents(false); // Reset the flag
  //     }
  //   }
  // }, [scrollToEvents]);


  const renderMenu = () => {
    return (
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 text-center align-items-center">
        {headerMenu.map((menuItem) => (
          <li
            key={menuItem.id}
            className={`nav-item ${menuItem ? "dropdown" : ""}`}
          >
            {menuItem.attributes.title === "Events" ? (
              <ScrollLink
                className="nav-link"
                to="events"
                smooth={true}
                duration={750}
                onClick={handleScrollToEvents}
              >
                {menuItem.attributes.title}
              </ScrollLink>
            ) : (
              <Link
                className="nav-link"
                to={`${menuItem && menuItem.attributes && menuItem.attributes.url}`}
              >
                {menuItem.attributes.title}{" "}
                {(menuItem.attributes.title === "InfraScan™ LSM/PEM Systems" ||
                  menuItem.attributes.title === "Applications") && (
                    <i
                      className={`fa-sharp fa-solid fa-caret-down ${menuItem.showDropdown ? "arrow-up" : "arrow-down"}`}
                    ></i>
                  )}
              </Link>
            )}
            {menuItem.attributes.title === "InfraScan™ LSM/PEM Systems" && (
              <ul
                style={{ background: "#E0000D" }}
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {product.map((submenu) => (
                  <li key={submenu.id}>
                    <a
                      className="dropdown-item"
                      href={submenu.attributes.slug || 'fallback-slug'}
                    >
                      {submenu.attributes.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
            {menuItem.attributes.title === "Applications" && (
              <ul
                style={{ background: "#E0000D" }}
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {technologies.map((submenu) => (
                  <li key={submenu.id}>
                    <a
                      className="dropdown-item"
                      href={submenu.attributes.slug || 'fallback-slug'}
                    >
                      {submenu.attributes.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return <>{headerMenu.length > 0 ? renderMenu() : ""}</>;
}

export default Nav;
