import React, { useState, useEffect } from "react";

const useErrorBoundary = () => {
  const [hasError, setHasError] = useState(false);
  const [errorInfo, setErrorInfo] = useState(null);

  const catchError = (error, info) => {
    setHasError(true);
    setErrorInfo({ error, info });
    console.error("Caught an error:", error, info);
  };

  return {
    hasError,
    errorInfo,
    catchError,
  };
};

const ErrorBoundary = ({ children }) => {
  const { hasError, catchError } = useErrorBoundary();

  useEffect(() => {
    const errorHandler = (error, info) => {
      catchError(error, info);
    };

    // You can use window.onerror or React's built-in error boundaries
    // to catch errors globally
    window.onerror = (message, source, lineno, colno, error) => {
      errorHandler(error, { message, source, lineno, colno });
      return true; // Prevent the default handler
    };

    return () => {
      window.onerror = null; // Cleanup
    };
  }, [catchError]);

  if (hasError) {
    return <h1>Something went wrong!</h1>;
  }

  return children;
};

export default ErrorBoundary;
