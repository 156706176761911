import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import Layout from "../common/Layout";
import { strapi_base_url } from "../utils/base_url";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// const AboutImageSkeleton = () => (
//   <div>
//     <Skeleton height={500} />
//   </div>
// );

function About() {
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    fetch(`${strapi_base_url}/api/about?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setData2(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const content =
    data2 &&
    data2.data &&
    data2.data.attributes &&
    data2.data.attributes.content;
  const bannerImage =
    data2 &&
    data2.data &&
    data2.data.attributes &&
    data2.data.attributes.bannerImage &&
    data2.data.attributes.bannerImage.data[0] &&
    data2.data.attributes.bannerImage.data[0].attributes &&
    data2.data.attributes.bannerImage.data[0].attributes.url;
  return (
    <div>
      <SEO
        title={
          data2 &&
          data2.data &&
          data2.data.attributes &&
          data2.data.attributes.metaTitle
        }
        description={
          data2 &&
          data2.data &&
          data2.data.attributes &&
          data2.data.attributes.metaDescription
        }
      />
      <Layout>
        {/* {loading ? (
          <AboutImageSkeleton />
        ) : ( */}
        <div className="hero_shape position-relative">
          <div
            style={{
              position: "relative",
            }}
            className="d-flex align-items-center justify-content-center text-center"
          >
            {/* <img
                src={`${strapi_base_url}${bannerImage}`}
                style={{ width: "100%", height: "500px" }}
              ></img> */}
            {loading && <div className="skeleton-loader"></div>}

            {/* Image with onLoad event to change state once loaded */}
            <img
              src={`${strapi_base_url}${bannerImage}`}
              alt="Hero"
              width={"100%"}
              height={"500px"}
              // style={{ width: "100%", height: "500px" }}
              onLoad={handleImageLoad}
              style={
                loading ? { visibility: "hidden" } : { visibility: "visible" }
              } // Hide the image while loading
            />
            {/* <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
                zIndex: 1, // Ensure overlay is above the background image
              }}
            /> */}
            {/* <div
              className="container bg_text"
              style={{ position: "relative", zIndex: 2 }}
            >
              <h1>
                {" "}
                {data2 &&
                  data2.data &&
                  data2.data.attributes &&
                  data2.data.attributes.bannerTitle}
              </h1>
              <p>
                <a href="/">Home</a>
                {data2 &&
                  data2.data &&
                  data2.data.attributes &&
                  data2.data.attributes.bannerText}
              </p>
            </div> */}
          </div>
        </div>
        {/* )} */}
        {/* blog_details */}
        <div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Layout>
    </div>
  );
}

export default About;
