import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import HeaderOne from "../common/header/HeaderOne";
import Footer from "../common/footer/Footer";
import GetinTouch from "../elements/GetinTouch";
import { strapi_base_url } from "../utils/base_url";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// const CareerImageSkeleton = () => (
//   <div>
//     <Skeleton height={500} />
//   </div>
// );

function Careers() {
  const [careerData, setCareerData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    fetch(`${strapi_base_url}/api/career?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setCareerData(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const content =
    careerData &&
    careerData.data &&
    careerData.data.attributes &&
    careerData.data.attributes.content;
  const bannerImage =
    careerData &&
    careerData.data &&
    careerData.data.attributes &&
    careerData.data.attributes.bannerImage &&
    careerData.data.attributes.bannerImage.data[0] &&
    careerData.data.attributes.bannerImage.data[0].attributes &&
    careerData.data.attributes.bannerImage.data[0].attributes.url;

  return (
    <div>
      <SEO
        title={
          careerData &&
          careerData.data &&
          careerData.data.attributes &&
          careerData.data.attributes.metaTitle
        }
        description={
          careerData &&
          careerData.data &&
          careerData.data.attributes &&
          careerData.data.attributes.metaDescription
        }
      />
      <>
        <main className="page-wrapper">
          <HeaderOne
            btnStyle="btn-small round btn-icon"
            HeaderSTyle="header-not-transparent"
          />
          {/* {loading ? (
            <CareerImageSkeleton />
          ) : ( */}
            <div className="hero_shape position-relative">
              <div
                // style={{
                //   backgroundImage: `url(${strapi_base_url}${bannerImage})`,
                //   height: "550px",
                //   width: "100%",
                //   backgroundRepeat: "no-repeat",
                //   backgroundSize: "cover",
                //   backgroundPosition: "center",
                //   position: "relative",
                // }}
                className="d-flex align-items-center justify-content-center text-center"
              >
                {loading && <div className="skeleton-loader"></div>}

                {/* Image with onLoad event to change state once loaded */}
                <img
                  src={`${strapi_base_url}${bannerImage}`}
                  alt="Hero"
                  width={"100%"}
                  height={"500px"}
                  // style={{ width: "100%", height: "500px" }}
                  onLoad={handleImageLoad}
                  style={
                   loading
                      ? { visibility: "hidden" }
                      : { visibility: "visible" }
                  } // Hide the image while loading
                />
                {/* <img
                  src={`${strapi_base_url}${bannerImage}`}
                  style={{ width: "100%", height: "500px" }}
                ></img> */}

                {/* <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
                  zIndex: 1, // Ensure overlay is above the background image
                }}
              /> */}
                {/* <div
                className="container bg_text"
                style={{ position: "relative", zIndex: 2 }}
              >
                <h1>
                  {careerData &&
                    careerData.data &&
                    careerData.data.attributes &&
                    careerData.data.attributes.bannerTitle}
                </h1>
                <p>
                  <a href="/">Home</a>
                  {careerData &&
                    careerData.data &&
                    careerData.data.attributes &&
                    careerData.data.attributes.bannerText}
                </p>
              </div> */}
              </div>
            </div>
          {/* )} */}
          <div>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <GetinTouch />
          <Footer />
        </main>
      </>
    </div>
  );
}

export default Careers;
